import React from "react"
import { useStaticQuery, graphql } from "gatsby"

const TagSection = () => {
  const data = useStaticQuery(graphql`
    query {
      tag: file(relativePath: { eq: "products/tag.svg" }) {
        publicURL
      }
      brasilMap: file(relativePath: { eq: "products/brasil-map.svg" }) {
        publicURL
      }
    }
  `)

  return (
    <section className="section section-tag section__right-chamfer">
      <div className="container">
        <h2 className="title has-text-white">
          Tag de <br className="is-hidden-desktop" />
          pedágio
        </h2>

        <div className="images">
          <div className="image-tag"></div>
          <img
            className="brasil-map is-hidden-mobile"
            src={data.brasilMap.publicURL}
            alt=""
          />
        </div>

        <img
          className="tag-sticker"
          src={data.tag.publicURL}
          alt="tag Zul+"
        />

        <p className="subtitle is-hidden-desktop">
          A tag Zul+ é aceita em todos os pedágios do país e não tem
          mensalidade.
        </p>

        <div className="is-hidden-mobile">
          <div className="columns">
            <div className="column is-two-thirds">
              <p className="subtitle">
                Viaje pelo Brasil e passe por todos <br />
                os pedágios sem pegar filas com a tag <br />
                pré-paga do Zul+.
              </p>
            </div>
          </div>
          <div className="columns">
            <div className="column is-two-thirds">
              <p className="subtitle">
                Peça pelo app e receba sua tag em casa.
              </p>
            </div>
          </div>
        </div>
        {/* <div className="is-clearfix">
          <a href="" className="button know-more is-pulled-right">
            Saiba mais
            <span className="icon is-small">
              <i className="zul-icon-arrowr"></i>
            </span>
          </a>
        </div> */}
      </div>
    </section>
  )
}

export default TagSection
